// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


import {
  BasicSetting,
  BillingandPlans,
  Logout
} from "./assets";

export const configJSON = require("./config");

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  profile:any;
  selectedItem?: SelectedItem;
}
interface Item {
  id: number | null;
  type: string;
  title: string;
  question: string;
  icon: any;
}
interface SelectedItem {
  id: number | null,
  question_type?: string,
  question_title?: string,
  options?: any
  answer?: any
  required?: boolean
  minumum?: number
  maximum?: number
  ind?: number
}
interface S {
  activeItem?: number | null
  selectedItem?: Item
  renderQuestions: Item[]
  tabValue: any;
  showLogoutDialog: any,
}

interface SS {
  id: any;
}

export default class AdminSurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    // Customizable Area Start
    this.state = {
      activeItem: 1,
      selectedItem: { id: null, type: '', title: '', question: '', icon: null,} as Item,
      renderQuestions: [],
      tabValue: 0,
      showLogoutDialog: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  renderItems = () => {
    return [
      [
        { id: 1, type: 'basicSettings',question_type:"", title: 'Basic settings',required: false, icon: BasicSetting ,},
      ],
      [
        { id: 2, type: 'billingPlans', question_type:"", title: 'Billing and Plans',  required: false, icon: BillingandPlans },
      ],
      [
        { id: 3, type: 'logout', title: 'Log out', question_type:"",  required: false, icon: Logout ,onClick: this.handleLogout},
      ],
    ]
  };

  handleLogout = () => {
    this.setState({ showLogoutDialog: true });
  };

  handleConfirmLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    this.setState({ showLogoutDialog: false });
    this.props.navigation.navigate('AuthBlock');
  };

  handleCancelLogout = () => {
    this.setState({ showLogoutDialog: false });
  };

  onItemClick = (data: any): void => {
    const { icon, ...val } = data;
    this.setState({ selectedItem: val ,activeItem: data.id});
  }


  // Customizable Area End
}

// Customizable Area End