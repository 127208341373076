import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const isEmail = (val: string) => {
  let regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!regEmail.test(val)) {
    return 'Invalid email address';
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAdmin: boolean,
  tabValue: number,
  step: number,
  dialogBoxOpen: boolean,
  isForm: number,
  emailError: string;
  passwordError: string;
  AdminSection: boolean;
  showPasswordConfirm: boolean;
  touched: boolean;
  snackbar: {
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    open: boolean;
  }
  signUpForm: {
    email: string;
    password: string;
    confirm_password: string;
    first_name: string;
    last_name: string;
    industry_sector: number;
    company: string;
    terms_of_service: boolean;
  }
  logInForm: {
    email: string;
    password: string;
  }
  errors: {
    email: string;
    password: string;
    confirm_password: string;
    first_name: string;
    last_name: string;
    company: string;
    terms_of_service: string;
  }
  code: string[];
  signUpConfirmaionDialogVisible: boolean;
  industrySectors: any[]
  termsAndConditionDialogVisible: boolean;
  isTermsAndCondition: boolean;
  termsAndConditon: string;
  privacyPolicy: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";

  public RequestMessage = {
    LoginAdminUser: this.buildRequestMessage(Method.POST),
    signUpAdminUser: this.buildRequestMessage(Method.POST),
    verifyAdminUser: this.buildRequestMessage(Method.POST),
    resendCode: this.buildRequestMessage(Method.POST),
    fetchIndustries: this.buildRequestMessage(Method.GET),
    getTermsConditions: this.buildRequestMessage(Method.GET),
    getPrivacyPolicy: this.buildRequestMessage(Method.GET),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];

    this.state = {
      logInForm: {
        email: '',
        password: ''
      },
      code: ['', '', '', ''],
      isAdmin: true,
      tabValue: 0,
      step: 2,
      dialogBoxOpen: false,
      isForm: 0,
      emailError: "",
      passwordError: "",
      AdminSection: true,
      showPasswordConfirm: false,
      touched: false,
      snackbar: {
        message: '',
        severity: 'info',
        open: false
      },
      signUpForm: {
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        industry_sector: 0,
        company: '',
        terms_of_service: false,
      },
      errors: {
        email: configJSON.errorEmailNotValid,
        password: configJSON.errorPasswordNotValid,
        confirm_password: configJSON.errorPasswordNotMatch,
        first_name: configJSON.errorFieldRequired,
        last_name: configJSON.errorFieldRequired,
        company: configJSON.errorFieldRequired,
        terms_of_service: configJSON.errorPrivacyPolicy
      },
      signUpConfirmaionDialogVisible: false,
      industrySectors: [],
      termsAndConditionDialogVisible: false,
      isTermsAndCondition: true,
      termsAndConditon: '',
      privacyPolicy: '',
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {

    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    await Promise.all([
      this.fetchIndustrySectors(),
      this.getTermsConditons(),
      this.getPrivacyPolicy()
    ])
    // Customizable Area End
  }

  // Customizable Area Start

  handleLoginUserResponse = (response: any) => {
    if (response?.error) {
      const error = response.error;
      this.setState({
        snackbar: {
          message: error,
          severity: 'error',
          open: true
        }
      });
    } else {
      localStorage.setItem("token", response?.meta?.token);
      localStorage.setItem("refresh_token", response?.meta?.refresh_token);
      if (response?.user) {
        const user = {
          id: response.user?.id,
          type: response.user?.role,
          attributes: {
            email: response.user?.email,
            first_name: response.user?.first_name,
            last_name: response.user?.last_name,
            industry_sector: response.user?.industry_sector,
            company: response.user?.company,
            local_authority: response.user?.local_authority,
            terms_of_service: response.user?.terms_of_service,
            activated: response.user?.activated,
            confirmed: response?.user?.confirmed,
            avatar: response?.user?.avatar,
            has_company: response?.user?.has_company,
            has_districts: response?.user?.has_districts,
            has_industry_sector: response?.user?.has_industry_sector,
            is_tutorial_completed: response?.user?.is_tutorial_completed,
          }
        }

        localStorage.setItem("user", JSON.stringify(user));
      }
      
      const msg: Message = new Message(
        getName(MessageEnum.NavigationDraftSurveyMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  handleSignUpResponse = (response: any) => {
    if (response?.error) {
      const error = response.error;
      this.setState({
        snackbar: {
          message: error,
          severity: 'error',
          open: true
        }
      });
    } else {
      localStorage.setItem("user", JSON.stringify(response.data));
      this.setState({ signUpConfirmaionDialogVisible: true });
    }
  };

  handleVerifyResponse = (response: any) => {
    if (response?.message) {
      const error = response.message;
      this.setState({
        snackbar: {
          message: error,
          severity: 'error',
          open: true
        }
      });
    } else {
      this.setState({ signUpConfirmaionDialogVisible: true });
    }
  };

  handleResendCodeResponse = (response: any) => {
    if (!response.error) {
      this.setState({
        snackbar: {
          message: 'Code sent successfully',
          severity: 'success',
          open: true
        }
      });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (callID) {
      case this.RequestMessage.LoginAdminUser.messageId:
        this.handleLoginUserResponse(response);
        break;
      case this.RequestMessage.signUpAdminUser.messageId:
        this.handleSignUpResponse(response);
        break;
      case this.RequestMessage.verifyAdminUser.messageId:
        this.handleVerifyResponse(response);
        break;
      case this.RequestMessage.resendCode.messageId:
        this.handleResendCodeResponse(response);
        break;
      case this.RequestMessage.fetchIndustries.messageId:
        this.handleFetchIndustrySectors(response);
        break;
      case this.RequestMessage.getTermsConditions.messageId:
        this.handleTermsAndConditionResponse(response);
        break;
      case this.RequestMessage.getPrivacyPolicy.messageId:
        this.handlePrivacyPolicyResponse(response);
        break;
    }
  }

  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  private buildRequestMessage(method: Method): Message {
    const requestMessageForSurvey = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessageForSurvey.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessageForSurvey.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessageForSurvey.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForSurvey;
  }

  loginAdminUser = (form: any) => {
    this.RequestMessage.LoginAdminUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAdminUserEndpoint
    );

    this.RequestMessage.LoginAdminUser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.LoginAdminUser.id, this.RequestMessage.LoginAdminUser);
  };

  signUpAdminUser = (form: any) => {
    this.RequestMessage.signUpAdminUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAdminUserEndpoint
    );

    this.RequestMessage.signUpAdminUser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.signUpAdminUser.id, this.RequestMessage.signUpAdminUser);
  };

  verifyUserAccount = (form: any) => {
    this.RequestMessage.verifyAdminUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyAdminUserEndpoint
    );

    this.RequestMessage.verifyAdminUser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.verifyAdminUser.id, this.RequestMessage.verifyAdminUser);
  };

  resendCodeRequest = (form: any) => {
    this.RequestMessage.resendCode.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendCodeEndpoint
    );

    this.RequestMessage.resendCode.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(this.RequestMessage.resendCode.id, this.RequestMessage.resendCode);
  };

  // Web Event Handling
  handleClickAdminCart = () => {
    this.setState({ isAdmin: !this.state.isAdmin });
  }

  handleClickUserCart = () => {
    this.setState({ isAdmin: false });
  }

  handleSendVerification = () => {
    const { code } = this.state;
    const confirmation_token = code.join('');
    if (confirmation_token.length === 4) {
      let data: any = localStorage.getItem('user');

      if (!data || data === 'undefined') {
        return;
      }

      data = JSON.parse(data);

      const {
        id = '',
        attributes
      } = data;

      const form = {
        confirmation_token,
        email: attributes?.email || '',
      };
      this.verifyUserAccount(form);
    } else {
      this.setState({
        snackbar: {
          message: 'Please enter 4 digit code',
          severity: 'error',
          open: true
        }
      });
    }
  }

  handleChangeTab = (event: any, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  resetLoginError = (val: string) => {
    if (val === 'email') {
      this.setState({ emailError: '' });
      return;
    }
    this.setState({ passwordError: '' });
  }

  handleClickLoginButton = () => {
    const { email, password } = this.state.logInForm;
    const emailError = isEmail(email);
    const passwordRegex = /^(?!^\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s$).{10,}$/;
    const passwordError = passwordRegex.test(password);

    if(email === '' && password === '')
    {
      this.setState({emailError:'Email address cannot be blank'})
      this.setState({passwordError:'Password cannot be blank'})
      return;
    }
    if(!emailError && password === '')
    {
      this.setState({passwordError:'Password cannot be blank'})
      return;
    }
    if(passwordError && email === '')
    {
      this.setState({emailError:'Email address cannot be blank'})
      return;
    }
    
    if (emailError) {
      this.setState({ emailError });
      return;
    }


    if (!passwordError) {
      this.setState({ passwordError: configJSON.errorInvalidPasswordMessage });
      return;
    }
    this.loginAdminUser({ email, password })
  }
  selectedIndustryId=(values: { industry_sector: any; })=>{
    return this.state.industrySectors.find((sector) => values.industry_sector === sector.name)?.id || ""
  }
  
  handleSignUpClick = () => {
    const values = this.state.signUpForm;
    const errors = this.state.errors;
    if(this.state.signUpForm.email === '')
    {
      errors.email = 'Error : Missing required field(s)'
    }
    const form = {
      ...values,
      industry_sector: this.selectedIndustryId(values),
      activated: "true",
      role: "admin",
      terms_of_service: String(values.terms_of_service)
    };

    this.setState({ touched: true });
    const errorExists = Object.values(errors).filter(el => el).length;
    if (!errorExists) {
      this.signUpAdminUser({ ...form });
    }
  }

  setShowPasswordConfirm = (value: boolean) => {
    this.setState({ showPasswordConfirm: value });
  }

  handleAdminCardClick = () => {
    this.setState({ step: 2 });
  }

  handleUserCardClick = () => {
    this.props.navigation.navigate("SignInUserBlock");
  }
  navigateTo = ()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAdminAuth)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        message: '',
        severity: 'info',
        open: false
      }
    });
  }

  handleError = (form: any) => {
    const {
      email,
      password,
      confirm_password,
      first_name,
      last_name,
      company,
      terms_of_service
    } = form;

    const passwordRegex = /^(?!^\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s$).{10,}$/;

    const firstNameError = first_name ? '' : configJSON.errorFieldRequired;
    const lastNameError = last_name ? '' : configJSON.errorFieldRequired;
    const companyError = company ? '' : configJSON.errorFieldRequired;
    const termsOfServiceError = terms_of_service ? '' : configJSON.errorPrivacyPolicy;
    const emailError = isEmail(email) ? configJSON.errorEmailNotValid : '';
    const passwordError = passwordRegex.test(password) ? '' : configJSON.errorPasswordNotValid;
    const confirmPasswordError = confirm_password !== password ? configJSON.errorPasswordNotMatch : '';

    const errors = {
      email: emailError,
      password: passwordError,
      confirm_password: confirmPasswordError,
      first_name: firstNameError,
      last_name: lastNameError,
      company: companyError,
      terms_of_service: termsOfServiceError
    };

    this.setState({ errors })
  }

  handleTermsOfServiceChange = (event: any) => {
    const { name, checked } = event.target;
    this.setState({ signUpForm: { ...this.state.signUpForm, [name]: checked } }, () => {
      this.handleError(this.state.signUpForm);
    });
  }

  handleSignUpFieldChange = (event: any) => {
    const { name, value } = event.target;
    if (name == "email") {
      const emailRegex = /^[a-zA-Z0-9.@+-_~]*$/;
      if (emailRegex.test(event.target.value)) {
        this.setState({ signUpForm: { ...this.state.signUpForm, [name]: value } }, () => {
          this.handleError(this.state.signUpForm);
        });
      } 
    }
    else {
      this.setState({ signUpForm: { ...this.state.signUpForm, [name]: value } }, () => {
        this.handleError(this.state.signUpForm);
      });
    }
  }

  handleLoginFieldChange = (event: any) => {
    const { name, value } = event.target;
    if (name == "email") {
      const emailRegex = /^[a-zA-Z0-9.@+-_~]*$/;
      if (emailRegex.test(event.target.value)) {
        this.setState({ logInForm: { ...this.state.logInForm, [name]: value } }, () => {
          this.resetLoginError(name);
        });
      }
    } else {
      this.setState({ logInForm: { ...this.state.logInForm, [name]: value } }, () => {
        this.resetLoginError(name);
      });
    }
  }

  handleVerificationCodeChange = (code: any) => {
    this.setState({ code });
  }

  handleContinueToVerifyEmail = () => {
    this.setState({ signUpConfirmaionDialogVisible: false });
    this.setState({ step: 3 });
  }

  handleResendCode = () => {
    let data: any = localStorage.getItem('user');

    if (!data || data === 'undefined') {
      return;
    }

    data = JSON.parse(data);
    const email = data?.attributes?.email || '';

    if (!email) {
      this.setState({
        snackbar: {
          message: 'Email not found',
          severity: 'error',
          open: true
        }
      });
      return;
    }

    this.resendCodeRequest({ email });
  }

  onSignUpClick = () => {
    this.setState({ tabValue: 1 });
  };

  handleEmailVerified = () => {
    this.setState({ signUpConfirmaionDialogVisible: false });
    this.setState({ step: 2, tabValue: 0 });
  }

  fetchIndustrySectors = () => {
    this.RequestMessage.fetchIndustries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industrySectorsEndpoint
    );

    runEngine.sendMessage(this.RequestMessage.fetchIndustries.id, this.RequestMessage.fetchIndustries);
  }

  getTermsConditons = () => {
    this.RequestMessage.getTermsConditions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsConditions
    );

    runEngine.sendMessage(this.RequestMessage.getTermsConditions.id, this.RequestMessage.getTermsConditions);
  };

  getPrivacyPolicy = () => {
    this.RequestMessage.getPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    runEngine.sendMessage(this.RequestMessage.getPrivacyPolicy.id, this.RequestMessage.getPrivacyPolicy);
  };

  handleTermsAndConditionResponse = (response: any) => {
    if(response?.description) {
      this.setState({ termsAndConditon: response.description });
    }
  }

  handlePrivacyPolicyResponse = (response: any) => {
    if(response?.description) {
      this.setState({ privacyPolicy: response.description });
    }
  }

  handleFetchIndustrySectors = (response: any) => {
    if(response?.industry_sectors?.length) {
      this.setState((prevState) => ({
        industrySectors: response.industry_sectors,              // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	
        signUpForm: {...prevState.signUpForm, industry_sector: response.industry_sectors?.[0]?.id}}))
    }
  };

  renderTermsAndPrivacyData = () => {
    if(this.state.isTermsAndCondition) {
      return {
        title: 'Terms and conditions',
        data: { description: this.state.termsAndConditon }
      };
    } else {
      return {
        title: 'Privacy policy',
        data: { description: this.state.privacyPolicy }
      };
    }
  }

  handleCloseTermsAndCondtionDioalg = () => {
    this.setState({ termsAndConditionDialogVisible: false });
  }

  handleOpenTermsAndConditionDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndCondition: true });
  }

  handleOpenPrivacyPolicyDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndCondition: false });
  }
  // Customizable Area End
}

