import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie } from './ManageCookies';

const ProductTour = ({ navigate }: { navigate: any }) => {
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#open-survey",
            content: <div style={{ maxWidth: "400px", padding: "10px" }}>
                <Box>
                    <Typography>Click here to create a survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            OnNext: () => {
                setCurrentStep((prev) => prev - 1)
                setTourLoading(true);
            },
            footer: () => { return "" },
            position: "left",
            disableScroll: true,
        },
        {
            selector: "#create-survey",
            content: <div style={{ maxWidth: "400px", padding: "10px" }}>
                <Box>
                    <Typography>Fill in the survey details</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            OnNext: () => {
                navigate.navigate("SurveyQuestion")
                setIsOpen(false);
                saveToCookie("Part one completed");
            },
            footer: () => { return "" },
            position: "right",
            disableScroll: true,
        },
    ];

    const getOpenTour = (part: string | null) => {
        if (part !== "Part three completed" && part === "Part two completed") {
            setCurrentStep(0);
            setSteps?.([{
                selector: "#live-survey-sample",
                content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                    <Box>
                        <Typography>Once your surveys are live, you can view live surveys in this tab schedule survey</Typography>
                    </Box>
                </div>,
                stepInteraction: false,
                footer: () => { return "" },
                nextIsClose: true,
                position: "left",
                disableScroll:true,
                OnNext: () => {
                    saveToCookie("Part three completed");
                    setIsOpen(false);
                    // this.setState({ tabValue: 0 })
                }
            }]);
            setIsOpen(true)
        }
    }

    useEffect(() => {
        const part = getCookie("productTour");
        if (!part) {
            setSteps?.(steps);
            setIsOpen(true);
            return
        }
        getOpenTour(part)
    }, [])

    return null
}

export default ProductTour