import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  isOpen: boolean;
  group: string,
  survey: string,
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  errors: {
    group?: string;
    survey?: string;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      isOpen: false,
      group: "",
      survey: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      errors: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token
      })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {

        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === 'string') {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else if (apiRequestCallId != null) {

        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({ ...CalendarProviderDetailsApiMessage, setApiCallId: 'serviceProviderDetailApiId' });
        return;

      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || '';
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    }
  }

   events = [
    {
      title: 'Morning Meeting',
      // start: new Date(2025, 0, 25, 9, 0),
      // end: new Date(2025, 0, 30, 10, 0),
      status: "Live",
    },
    {
      title: 'Morning Meeting',
      // start: new Date(2025, 0, 25, 9, 0),
      // end: new Date(2025, 0, 25, 10, 0),
      status: "Live",
    },
    {
      title: 'Team Discussion',
      // start: new Date(2025, 0, 25, 11, 0),
      // end: new Date(2025, 0, 25, 12, 0),
      status: "Scheduled",
    },
    {
      title: 'Meeting',
      // start: new Date(2025, 0, 25, 10, 0),
      // end: new Date(2025, 0, 25, 12, 0),
      status: "Live",
    },
    {
      title: 'Lunch',
      // start: new Date(2025, 0, 26, 13, 0),
      // end: new Date(2025, 0, 26, 13, 30),
      status: "Live",
    },
    {
      title: 'Break',
      // start: new Date(2025, 0, 26, 13, 30),
      // end: new Date(2025, 0, 26, 14, 0),
      status: "Live",
    },
    {
      title: 'Testing',
      // start: new Date(2025, 0, 26, 9, 0),
      // end: new Date(2025, 0, 26, 10, 0),
      status: "Past",
    },
    {
      title: 'This is testing',
      // start: new Date(2025, 0, 26, 10, 0),
      // end: new Date(2025, 0, 26, 12, 0),
      status: "Live",
    },
  ];
  
  handleOpenMoadal = () => {
    this.setState({ isOpen: true })
  }

  validateForm = () => {
    interface errors {
      group?: string;
      survey?: string;
      startDate?: string;
      startTime?: string;
      endDate?: string;
      endTime?: string;
    };
    const { group, survey, startDate, startTime, endDate, endTime } = this.state;
    let errors: errors = {};
    if (!group) errors.group = "Group selection is required";
    if (!survey) errors.survey = "Survey selection is required";
    if (!startDate) errors.startDate = "Start date is required";
    if (!startTime) errors.startTime = "Start time is required";
    if (!endDate) errors.endDate = "End date is required";
    if (!endTime) errors.endTime = "End time is required";
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
    if (this.validateForm()) {
      const { group, survey, startDate, startTime, endDate, endTime } = this.state;
      const startDateTime = new Date(`${startDate} ${startTime}`);
      const endDateTime = new Date(`${endDate} ${endTime}`);

      const payload = {
        title: survey,
        start: startDateTime,
        end: endDateTime,
        status: "Open",
      };
      this.events = [...this.events, payload]
      this.setState({ isOpen: false });
    }
  };

  handleInputChange = (field: string, value: any) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: { ...prevState.errors, [field]: "" }
    }));
  };

  generateTimeSlots = () => {
    const times = [];
    for (let hour = 1; hour <= 12; hour++) {
      times.push(`${hour}:00 AM`);
    }
    for (let hour = 1; hour <= 12; hour++) {
      times.push(`${hour}:00 PM`);
    }
    return times;
  };
  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === 'serviceProviderDetailApiId') {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === 'serviceProviderScheduleApiId') {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  // Customizable Area End
}