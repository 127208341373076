import React from "react";
// Customizable Area Start

// Customizable Area End

import SchedulingController, {
  Props
  // Customizable Area Start
  // Customizable Area End
} from "./SchedulingController";
import { Box, Button, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, Typography } from "@material-ui/core";
import Calender from "../../../components/src/Calender/Calender.web";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const timeSlots = this.generateTimeSlots();
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <MainContainer>
        <Box sx={{ width: '100%' }} className="tabs" >
          <Box className="cardHeader" >
            <Typography className="header-text">
              Calendar
            </Typography>
          </Box>
        </Box>
        <Calender handleOpenMoadal={this.handleOpenMoadal} events={this.events} />
        <Modaldialog data-test-id="modal" open={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}>
          <div className="modalBody">
            <Container maxWidth="sm">
              <Typography variant="h5" align="center" gutterBottom>
                Schedule Survey
              </Typography>
              <FormControl fullWidth margin="normal" variant="outlined" error={!!this.state.errors.group}>
                <Typography variant="body1">Select the group</Typography>
                <Select data-test-id="select-group" value={this.state.group} onChange={(e) => this.handleInputChange("group", e.target.value)}>
                  <MenuItem value="">Select Group</MenuItem>
                  <MenuItem value="Lorem Ipsum">Lorem Ipsum</MenuItem>
                </Select>
                <Typography color="error">{this.state.errors.group}</Typography>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined" error={!!this.state.errors.survey}>
                <Typography variant="body1">Select the survey</Typography>
                <Select data-test-id="select-survey" value={this.state.survey} onChange={(e) => this.handleInputChange("survey", e.target.value)}>
                  <MenuItem value="">Select Survey</MenuItem>
                  <MenuItem value="Survey Name">Survey Name</MenuItem>
                </Select>
                <Typography color="error">{this.state.errors.survey}</Typography>
              </FormControl>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Start Date</Typography>
                  <TextField
                    data-test-id="start-date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={this.state.startDate}
                    onChange={(e) => this.handleInputChange("startDate", e.target.value)}
                    error={!!this.state.errors.startDate}
                    helperText={this.state.errors.startDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Time</Typography>
                  <FormControl fullWidth variant="outlined" error={!!this.state.errors.startTime}>
                    <Select data-test-id="start-time" value={this.state.startTime} onChange={(e) => this.handleInputChange("startTime", e.target.value)}>
                      <MenuItem value="">Select Time</MenuItem>
                      {timeSlots.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">{this.state.errors.startTime}</Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">End Date</Typography>
                  <TextField
                    data-test-id="end-date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={this.state.endDate}
                    onChange={(e) => this.handleInputChange("endDate", e.target.value)}
                    error={!!this.state.errors.endDate}
                    helperText={this.state.errors.endDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Time</Typography>
                  <FormControl fullWidth variant="outlined" error={!!this.state.errors.endTime}>
                    <Select data-test-id="end-time" value={this.state.endTime} onChange={(e) => this.handleInputChange("endTime", e.target.value)}>
                      <MenuItem value="">Select Time</MenuItem>
                      {timeSlots.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">{this.state.errors.endTime}</Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
            <div className="buttons">
              <Button data-test-id="submit-btn" className="primaryButton" type="button" onClick={this.handleSubmit}>
                Schedule
              </Button>
              <Button data-test-id="cancel-btn" onClick={() => this.setState({ isOpen: false })} className="secondaryButton">
                Cancel
              </Button>
            </div>
          </div>
        </Modaldialog>
      </MainContainer>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const MainContainer = styled(Box)({
  margin: "50px",
  boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
  borderRadius: "20px",
  background: "#FFFFFF",
  height: "auto",
  margintop: "0px",
  paddingTop: "0px",
  flex: 1,
  "@media (max-width: 500px)": {
    margin: "20px",
  },
  width: "100%",
  color: "#616161",
  fontFamily: "'Raleway', sans-serif",
  "& .cardHeader": {
    paddingLeft: '20px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 402px)": {
      padding: '0 10px',
    }
  },
  "& .header-text": {
    color: "#202020",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    fontFamily: "aeonik-bold",
    marginRight: "24px",
    textDecoration: "none",
    cursor: "pointer",
    textTransform: "none"
  },
  "& .button-box": {
    width: "100%",
    color: "#616161",
    padding: "20px",
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
});

const Modaldialog = styled(Dialog)({
  borderRadius: '16px',
  color: "#000000",
  "& .modalBody": {
    borderRadius: '16px',
    background: '#FFF',
    padding: '20px',
    color: "#000000",
    width: "690px"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "unset"
  },
  "& .buttons": {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
  "& .secondaryButton": {
    alignItems: 'center',
    textTransform: 'none' as const,
    padding: '18px 32px',
    justifyContent: 'center',
    border: 'none',
    fontSize: '16px',
    fontWeight: 700,
    background: 'none',
    color: '#333',
    fontStyle: 'normal',
    lineHeight: 'normal',
    flex: '1 1 0px',
    fontFamily: 'aeonik-regular',
  },
})
// Customizable Area End
