export type StylesKeys = 'popover' | 'arrowTop' | 'arrowBottom' | 'arrowLeft' | 'arrowRight' | 'arrowCenter'

export type StylesObj = {
  [key in StylesKeys]?: StyleFn
}

export type StyleFn = (
  props: { [key: string]: any },
  state?: { [key: string]: any }
) => React.CSSProperties

export type Styles = {
  popover: StyleFn
  arrowTop: StyleFn
  arrowBottom: StyleFn
  arrowLeft: StyleFn
  arrowRight: StyleFn
  arrowCenter: StyleFn
}

export type StyleKey = keyof Styles

export const defaultStyles: Styles = {
  popover: () => ({
    position: 'fixed',
    minWidth: 300,
    maxWidth: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 0.5em 3em rgba(0, 0, 0, 0.3)',
    color: 'inherit',
    zIndex: 9999,
    transition: 'transform 0.3s',
    borderRadius: '8px',
    top: 0,
    left: 0,
    padding: 4
  }),
  arrowTop: () => ({
    borderBottom: '16px solid white',
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    height: 0,
    // left: '50%',
    position: 'absolute',
    top: '-14px',
    transform: 'translateX(-50%)',
    width: 0,
  }),
  arrowBottom: () => ({
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    borderTop: '16px solid white',
    bottom: '-14px',
    height: 0,
    // left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    width: 0,
  }),
  arrowRight: () => ({
    borderBottom: '16px solid transparent',
    borderLeft: '16px solid white',
    borderTop: '16px solid transparent',
    height: 0,
    position: 'absolute',
    right: '-14px',
    // top: '50%',
    transform: 'translateY(-50%)',
    width: 0,
  }),
  arrowLeft: () => ({
    borderBottom: '16px solid transparent',
    borderRight: '16px solid white',
    borderTop: '16px solid transparent',
    height: 0,
    left: '-14px',
    position: 'absolute',
    // top: '50%',
    transform: 'translateY(-50%)',
    width: 0,
  }),
  arrowCenter: () => ({ display: 'none' })
}

export type getStylesType = (key: StylesKeys, extra?: any) => {}

export function stylesMatcher(styles: StylesObj) {
  return (key: StylesKeys, state: {}): {} => {
    const base = defaultStyles[key](state)
    const custom = styles[key]
    return custom ? custom(base, state) : base
  }
}