// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import { runEngine } from "../../../framework/src/RunEngine"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import moment from "moment"
import React from "react"
import { getCookie, saveToCookie } from "../../../components/src/Tour/ManageCookies"
import { TourProps } from "../../../components/src/Tour"
import { Box, Typography } from "@material-ui/core"
export const configJSON = require("./config")


export interface Props extends TourProps {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  test: string,
  tabValue: any;
  open: boolean;
  cancelModal: boolean;
  adminSurvey: {
    selectedGroup: string;
    selectedTag: string;
    selectedSurvey: string;
    selectedStartDate: string | null;
    selectedStartTime: string;
    selectedEndDate: string;
    selectedEndTime: string;
    id: number
  };
  snackbar: {
    open: boolean;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  },
  selectFields: any[],
  selectedTags: any[],
  getPastSurveysMessageId: any,
  surveyData: any[]
  surveyDataLive: any[]
  surveyDataScheduled: any[]
  workingSlot: any
  groupList: any[],
  userList:any[],
  userFlag: boolean,
  selectedUsers:any[],
  isIndividualUserAdded:boolean[],
  selectedGroupList: number[],
  individualUserGroup:number|null,
  tagList: any[],
  selectedGroup: any[],
  scheduleError: any,
  isLiveSurveyModal: boolean,
  quickEnd: boolean
  quickSchedule: boolean,
  adminSelectedStartTime:string,
  adminSelectedEndTime:string,
  currStep:number;

  
}
interface SS {
  id: any;
}

export default class AdminSurveysController extends BlockComponent<
  Props,
  S,
  SS

> {

  getPastSurveysCallId: string = ""
  getLiveSurveyCallId: string = ""
  getScheduledCallId: string = ""
  onsubmitCallId: string = ""
  getGroupDetailCallId: string = ""
  getTagDetailCallId: string = ""
  getSurveyUserCallId: string = ""
  removeUserCallId: string = ""
  addUserCallId: string = ""


  tabs: any = {
    past: 0,
    live: 1,
    scheduled: 2,
    past2:3,
    0: 'past',
    1: 'live',
    2: 'scheduled',
    3: 'past2'
  }


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      test: '',
      tabValue: 0,
      open: false,
      cancelModal: false,
      currStep:0,
      adminSurvey: {
        selectedGroup: '',
        selectedTag: '',
        selectedSurvey: '',
        selectedStartDate: null,
        selectedStartTime: '',
        selectedEndDate: '',
        selectedEndTime: '',
        id: 0
      },
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      selectFields: [],
      getPastSurveysMessageId: "",
      surveyData: [],
      surveyDataLive: [],
      surveyDataScheduled: [],
      isIndividualUserAdded:[],
      groupList: [],
      userList:[],
      userFlag: false,
      selectedUsers:[],
      selectedGroupList: [],
      individualUserGroup:null,
      selectedGroup: [],
      tagList: [],
      selectedTags: [],
      scheduleError: false,
      workingSlot : [
        "",
        "12:00 AM",
        "12:30 AM",
        "01:00 AM",
        "01:30 AM",
        "02:00 AM",
        "02:30 AM",
        "03:00 AM",
        "03:30 AM",
        "04:00 AM",
        "04:30 AM",
        "05:00 AM",
        "05:30 AM",
        "06:00 AM",
        "06:30 AM",
        "07:00 AM",
        "07:30 AM",
        "08:00 AM",
        "08:30 AM",
        "09:00 AM",
        "09:30 AM",
        "10:00 AM",
        "10:30 AM",
        "11:00 AM",
        "11:30 AM",
        "12:00 PM",
        "12:30 PM",
        "01:00 PM",
        "01:30 PM",
        "02:00 PM",
        "02:30 PM",
        "03:00 PM",
        "03:30 PM",
        "04:00 PM",
        "04:30 PM",
        "05:00 PM",
        "05:30 PM",
        "06:00 PM",
        "06:30 PM",
        "07:00 PM",
        "07:30 PM",
        "08:00 PM",
        "08:30 PM",
        "09:00 PM",
        "09:30 PM",
        "10:00 PM",
        "10:30 PM",
        "11:00 PM",
        "11:30 PM",
        "12:00 AM"
    ],
     adminSelectedStartTime:"",
     adminSelectedEndTime:"",
      isLiveSurveyModal: false,
      quickEnd: false,
      quickSchedule: false,
      
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }

    switch(apiRequestCallId) {
      case this.getPastSurveysCallId:
        this.getPastDataCallresive(responseJson);
        break;
      case this.getLiveSurveyCallId:
        this.getLiveDataCallresive(responseJson);
        break;
      case this.getScheduledCallId:
        this.getScheduledDataCallresive(responseJson);
        break;
      case this.onsubmitCallId:
        this.submitDetailRecive(responseJson);
        break;
      case this.getGroupDetailCallId:
        this.getGroupDetailReceive(responseJson);
        break;
      case this.getTagDetailCallId:
        this.getTagDetailReceive(responseJson);
        break;
      case this.getSurveyUserCallId:
        this.getUserDetailReceive(responseJson);
        break;
      case this.removeUserCallId:
        this.handleUserRemoveResponse(responseJson);
        break;
      case this.addUserCallId:
        this.handleAddResponse(responseJson);
        break;
      default:
        runEngine.debugLog("Unrecognized API request call ID", apiRequestCallId);
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }
 
// Customizable Area Start
async componentDidMount(): Promise<void> {
  await Promise.all([this.getPastDetails(), this.getLiveDetails(), this.getScheduledDetails(), this.getGroupDetails(), this.getUserDetails(), this.getTagDetails()]);
  const part = getCookie("productTour");
  this.handleCookies(part);
}

  async componentDidUpdate(prevProps: Props, prevState: S): Promise<void> {
    if (
      prevState.selectedGroupList !== this.state.selectedGroupList ||
      prevState.individualUserGroup !== this.state.individualUserGroup ||
      prevState.selectedGroup !== this.state.selectedGroup
    ) {
      await Promise.all([
        this.state.selectedGroupList,
        this.state.individualUserGroup,
        this.getUserDetails(),
      ]);
    }
  
    if (prevState.userList !== this.state.userList) {
       this.updateIndividualUserAddedAndSelectedUsers();
    }
    if (prevState.individualUserGroup !== this.state.individualUserGroup ||prevState.selectedGroup !== this.state.selectedGroup) {
       this.handleSelectedGroupList();
    }
  }

handleCookies=(part:string| null)=>{
  if (part !== "Part three completed" && part === "Part two completed") { 
    this.setState({tabValue:1});
  }
}
  getPastDetails = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPastApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getPastSurveysCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPastDataCallresive = (responseJson: any) => {
    if (responseJson?.surveys?.data) {
      this.setState({ surveyData: responseJson.surveys.data.length ? responseJson.surveys.data : [] });
    }
  }


  getLiveDetails = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLiveApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getLiveSurveyCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLiveDataCallresive = (responseJson: any) => {
    if (responseJson?.surveys?.data) {
      let data= responseJson.surveys.data.length ? responseJson.surveys.data : [];
      this.setState({ surveyDataLive: data});
    }
  }

  
  getScheduledDetails = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getScheduledApiEndPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getScheduledCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getScheduledDataCallresive = (responseJson: any) => {
    if (responseJson?.surveys?.data) {
      this.setState({ surveyDataScheduled: responseJson.surveys.data.length ? responseJson.surveys.data : [] });
    }
  }

  editSubmitHandler = () => {
    if (this.state.isLiveSurveyModal) {
      this.onSubmitEditLiveSurvey()
    } else {
      this.onSubmitSurveyDetails()
    }
  }

  onSubmitSurveyDetails = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

      const startDateTime = `${this.state.adminSurvey.selectedStartDate} ${this.state.adminSurvey.selectedStartTime}`;
      const endDateTime = `${this.state.adminSurvey.selectedEndDate} ${this.state.adminSurvey.selectedEndTime}`;
      const startLocal = moment(startDateTime, "YYYY-MM-DD hh:mm A");
      const startUTC = startLocal.utc();
      const endLocal = moment(endDateTime, "YYYY-MM-DD hh:mm A");
      const endUTC = endLocal.utc();
      const start24HourFormat = startLocal.format("YYYY-MM-DD HH:mm");
      const end24HourFormat = endLocal.format("YYYY-MM-DD HH:mm");
      const startTime =start24HourFormat.split(' ')[1];
      const endTime = end24HourFormat.split(' ')[1];

    let formData = {
      admin_start_time : this.state.adminSurvey.selectedStartTime,
      admin_start_date : this.state.adminSurvey.selectedStartDate,
      start_date: startUTC?.format("YYYY-MM-DD"),
      start_time: startTime,
      end_date: endUTC.format("YYYY-MM-DD"),
      end_time: endTime,
      group_ids: this.state.selectedGroup,
      tag_ids: this.state.selectedTags.map(tag => +tag),
      name: this.state.adminSurvey.selectedSurvey,
      is_activated: 'true',
      is_start_now : this.state.quickSchedule
    };
    if (this.state.quickSchedule) {
      const current = moment()
      formData.start_date = current.format('YYYY-MM-DD')
      formData.start_time = current.add(1, 'minutes').format('HH:mm')
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editScheduleSurvey}?id=${this.state.adminSurvey.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    this.onsubmitCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmitEditLiveSurvey = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const endUTC = moment(`${this.state.adminSurvey.selectedEndDate} ${this.state.adminSurvey.selectedEndTime}`, "YYYY-MM-DD HH:mm").utc();

    let body = {
      group_ids: this.state.selectedGroup,
      end_date: endUTC?.format("YYYY-MM-DD"),
      end_time: endUTC?.format('HH:mm'),
      tag_ids: this.state.selectedTags.map(tag => +tag),
      is_activated: 'true',
      is_end_now: this.state.quickEnd
      
    };

    if (this.state.quickEnd) {
      const current = moment.utc();
      body.end_date = current.format('YYYY-MM-DD')
      body.end_time = current.add(1, 'minutes').format('HH:mm')
      
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editLiveSurvey}/${this.state.adminSurvey.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.onsubmitCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGroupDetails = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getGroupDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGroupDetailReceive = (responseJson: any) => {
    
    if (responseJson?.groups) {
      const sortedGroups = responseJson.groups.sort((a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else if (a.name) {
          return -1;
        } else if (b.name) {
          return 1;
        }
        return 0;
      });
      this.setState({ groupList: sortedGroups});
    }
  }

  getUserDetails = () => {
    const authToken = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
  
    const requestBody = {
      group_ids: this.state.selectedGroupList,
      individual_user_group: this.state.individualUserGroup
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveyUsersEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
  
    this.getSurveyUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  getUserDetailReceive = (responseJson: any) => {
    if (responseJson?.users) {
      const sortedUsers = responseJson.users.sort((a: any, b: any) => {
        if (a.first_name && b.first_name) {
          return a.first_name.localeCompare(b.first_name);
        } else if (a.first_name) {
          return -1;
        } else if (b.first_name) {
          return 1;
        }
        return 0;
      });
      this.setState({ userList: sortedUsers});
  
    }
  }

  removeUsers = (id: number) => {
    const individualUserGroupId = this.state.individualUserGroup;
    const authToken = localStorage.getItem('token');
  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
  
    const requestBody = {
      id: id,
      group_id: individualUserGroupId
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeUserGroupApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
  
    this.removeUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserRemoveResponse = (response: any) => {
    if (response) {
      this.getUserDetails();
    }
  }


  addUsers = (id: number) => {
    const individualUserGroupId = this.state.individualUserGroup;
    const authToken = localStorage.getItem('token');
  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
  
    const requestBody = {
      id: id,
      group_id: individualUserGroupId
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectUserGroupApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    
    this.removeUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddResponse = (response: any) => {
    if (response) {
      this.getUserDetails();
    }
  }

  handleSelectedGroupList = () => {
    const { selectedGroup, individualUserGroup } = this.state;
    const filteredGroup = selectedGroup.filter(group => group !== individualUserGroup);
    this.setState({ selectedGroupList: filteredGroup });
  };
  
  updateIndividualUserAddedAndSelectedUsers = () => {
    const individualUserAddedArray = this.state.userList.map(user => user.is_individual_user_added);
    const initialSelectedUsers = this.state.userList
      .filter(user => user.is_in_group || user.is_individual_user_added)
      .map(user => user.id);

    this.setState({ 
      isIndividualUserAdded: [...individualUserAddedArray],
      selectedUsers: initialSelectedUsers,
    });
    
  }


  getTagDetails = () => {

    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getTagDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTagDetailReceive = (responseJson: any) => {
    if (responseJson?.tags) {
      this.setState({ tagList: responseJson.tags });
    }
  }

  submitDetailRecive = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ scheduleError: false })
      if (this.state.isLiveSurveyModal) {
        this.getLiveDetails()
      } else {
        this.getScheduledDetails()
      }
      this.handleCancel()
    } else if (responseJson.errors) {
      this.setState({ scheduleError: responseJson.errors })
    }
    
  }



  handleSnackbarClose = () => {
    this.setState({ scheduleError: false });
  };

  renderBtntext = () => {
    return 'View results';
  }

  handleAddField = () => {
    const newField = this.state.selectedTags;
    newField.push(null)
    this.setState({
      selectedTags: [...newField]
    });
  };

  handleRemoveField = (id: number) => {
    const updatedTag = [...this.state.selectedTags];
    updatedTag.splice(id, 1)
    this.setState({
      selectedTags: updatedTag ,
    })
  };

  handleCheck = (event: any) => {
    const { value  } = event.target;
    this.setState({ selectedGroup: value});
  };
  handleCheckUser = (event : any)=>{
    const { value} = event.target;
    this.setState({ selectedUsers: value });
  }


  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      adminSurvey: {
        ...prevState.adminSurvey,
        [name]: value,
      },
    }));
  };

  handleChangeTags = (event: any, id: number) => {
    const { value } = event.target;
    const newTarget = [...this.state.selectedTags];
    newTarget[id] = value;
    this.setState({ selectedTags: [...newTarget] });
  };

  handleChangeAnother = (date: any) => {
    const formattedDate = moment(date, 'DD/MM/YY').format('YYYY-MM-DD');
    this.setState((prevState) => ({
      adminSurvey: {
        ...prevState.adminSurvey,
        selectedEndDate: formattedDate,
      },
    }));
  };

  handleStartDate = (date: any) => {
    const formattedDate = moment(date, 'DD/MM/YY').format('YYYY-MM-DD');
    this.setState((prevState) => ({
      adminSurvey: {
        ...prevState.adminSurvey,
        selectedStartDate: formattedDate,
        selectedEndDate: formattedDate,
      },
    }));
  }

  handleChangeTab = (event: any, newValue: number) => {
    // this.props.navigation.navigate("AdminSurvey", { page: this.tabs[newValue] })
    this.setState({ tabValue: newValue });
    if (newValue === 0) {
      this.getPastDetails()
    } else if (newValue === 1) {
      this.getLiveDetails()
    } else if (newValue === 2) {
      this.getScheduledDetails()
    } else if(newValue === 3)
    {
      this.getPastDetails()
    }
  };

  getCardDate = (date: string, time: string) => {
    const editedTime = time?.split("T")[1]?.slice(0, 5) || ''
    const local = moment(`${date} ${editedTime}`, "YYYY-MM-DD HH:mm")
    return local?.local()?.format("DD MMMM YYYY")
  }

  getLocalDate = (date: string, time: string) => {
    const local = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm")
    return local?.local()?.format("YYYY-MM-DD")
  }

  getLocalTime = (date: string, time: string) => {
    const local = moment.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm")
    return local?.local()?.format("HH:mm")
  }

  handleClickOpen = (schedule: any, relationships: any) => {

    this.setState({selectedGroupList:this.state.selectedGroup})
    const { id, group_ids, tag_ids, name, start_date, start_time, end_date, end_time, groups } = schedule;
    const startTime = start_time || "";
    const startTimetimeOnly = startTime.split("T")[1]?.slice(0, 5) || '';
    const endTime = end_time || "";
    const endTimetimeOnly = endTime.split("T")[1]?.slice(0, 5) || '';
    const adminSurvey = {
      selectedGroup: group_ids || '',
      selectedTag: tag_ids || '',
      selectedSurvey: name || '',
      selectedStartDate: this.getLocalDate(start_date, startTimetimeOnly) || '',
      selectedStartTime: this.getLocalTime(start_date, startTimetimeOnly) || '',
      selectedEndDate: this.getLocalDate(end_date, endTimetimeOnly) || '',
      selectedEndTime: this.getLocalTime(end_date, endTimetimeOnly) || '',
      id: Number(id)
    };
        this.setState({
      open: true,
      cancelModal: false,
      adminSurvey,
      selectedGroup: groups,
      // isLiveSurveyModal: true,
      quickEnd: false,
      quickSchedule: false
      // selectedGroup: relationships?.groups?.data ? relationships?.groups?.data.map((item: any) => +item?.id) : [],
      // selectedTags: relationships?.tags?.data?.length ? relationships?.tags?.data.map((item: any) => +item?.id) : [null]
    });
  };

  handleCancleModalOpen = (schedule: any) => {
    const adminSurvey = schedule;
    this.setState({
      open: true,
      cancelModal: false,
      adminSurvey,
    });
  }

  handleClose = () => {
    this.setState({ open: false, cancelModal: true });
  };

  handleCancel = () => {
    this.setState({ cancelModal: false, open: false });
  }

  quickHandler = () => {
    this.setState((prevState) => ({...prevState, quickEnd: !prevState.quickEnd}))
    
    
  }
  handlePastClick = () => {
    this.setState({tabValue:3});
  }
   renderSurveyTitle = (isLiveSurveyModal:boolean) => {
    return (
      <>
        {isLiveSurveyModal ? (
          <>Edit live survey</>
        ) : (
          <>Edit schedule survey</>
        )}
      </>
    );
  }
   isEndDateAndStartDateSame = () => {
    const selectedEndDate = this.state.adminSurvey.selectedEndDate;
    const selectedStartDate = this.state.adminSurvey.selectedStartDate;
    if (selectedEndDate && selectedStartDate) {
        const start_date = new Date(selectedStartDate).setHours(0, 0, 0, 0);
        const end_date = new Date(selectedEndDate).setHours(0, 0, 0, 0);
        const isEndDateAndStartDateSame = start_date === end_date;

        return isEndDateAndStartDateSame;
    }

    return false;
};

 groupRender = (value: unknown): React.ReactNode => {
  if (!Array.isArray(value)) return null;
  
  return value
    .map((id) => this.state.groupList.find((group) => group.id === id)?.name)
    .filter((name): name is string => name !== undefined)
    .join(", ");
};

userRender = (value: unknown): React.ReactNode => {
  if (!Array.isArray(value)) return null;

  return value
    .map((id) => this.state.userList.find((user) => user.id === id)?.first_name)
    .filter((name): name is string => name !== undefined)
    .join(", ");
};

validateStartTime = (time: any) => {
  return true;
};

validateEndTime = (time: any) => {
  this.setState({adminSelectedEndTime:time})
  return true;
};



  quickScheduleHandler = () => {
    this.setState((prevState) => ({ ...prevState, quickSchedule: !prevState.quickSchedule }))
  }

  getCustomSlot = (time: string) => {
    if (!this.state.workingSlot.includes(time)) {
      return [...this.state.workingSlot, time]
    }
    return [...this.state.workingSlot]
  }

  // Customizable Area End
}

// Customizable Area End