import React, { useState } from 'react';
import { Calendar, EventProps, HeaderProps, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './customCalender.css';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Card, CardContent, Chip, IconButton, Typography } from '@material-ui/core';

function CustomToolbar({ label, setCurrentView, onNavigate, onDateChange }: any) {
    return (
        <div className="toolbar">
            <button onClick={() => onNavigate('TODAY')} className="toolbar-button">
                Today
            </button>
            <button onClick={() => {
                setCurrentView("week")
                onNavigate('PREV')
            }}
                className="toolbar-button">
                ←
            </button>
            <DatePicker
                selected={new Date(label)}
                onChange={(date) => {
                    setCurrentView("week");
                    onDateChange(date);
                }}
                dateFormat="MMMM yyyy"
                className="datepicker-input"
            />
            <button onClick={() => { setCurrentView("week"); onNavigate('NEXT') }} className="toolbar-button">
                →
            </button>
        </div>
    );
}

function EventComponent(props: any) {
    const formatTime = (date: Date): string => {
        return date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        });
    };

    const { start, end } = props.event;
    const formattedTime = `${formatTime(start)} - ${formatTime(end)}`;

    const timeDiffInMs = end.getTime() - start.getTime();
    const timeDiffInHours = timeDiffInMs / (1000 * 60 * 60);


    return (
        <div>
            <Box className='double-event' style={{ backgroundColor: props.event.status === 'Live' ? '#E7F2DA' : '#E8EBFA' }}>
                <div className='full_event'>
                    <span className=''>{props.title}</span>
                    <Chip
                        label={props.event.status}
                        style={{
                            marginTop: '8px',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '3px',
                            position: 'relative',
                            backgroundColor: props.event.status === 'Live' ? '#BDDA9B' : '#BFCAE4',
                            color: props.event.status === 'Live' ? '#237B4B' : '#4B6EB0'
                        }}
                    />
                </div>
                <div>
                    <IconButton size='small' style={{ position: 'absolute', top: '25px', right: '8px' }}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </div>
            </Box>
            <Box className='double-event' style={{ backgroundColor: props.event.status === 'Live' ? '#E7F2DA' : '#E8EBFA' }}>
                <div className='full_event'>
                    <span className=''>{props.title}</span>
                    <Chip
                        label={props.event.status}
                        style={{
                            marginTop: '8px',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '3px',
                            position: 'relative',
                            backgroundColor: props.event.status === 'Live' ? '#BDDA9B' : '#BFCAE4',
                            color: props.event.status === 'Live' ? '#237B4B' : '#4B6EB0'
                        }}
                    />
                </div>
                <div>
                    <IconButton size='small' style={{ position: 'absolute', top: '25px', right: '8px' }}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </div>
            </Box>
        </div>
    );
}

function WeekHeader(props: any) {

    const daysMap: { [key: string]: string } = {
        Sun: 'Sunday',
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday'
    };

    const day = props.label.split(" ")
    return (
        <div className="header">
            <span className='header-day'>
                {daysMap[day[1]]}
            </span>
            <span className='header-date'>
                {day[0]}
            </span>
        </div>
    );
}

function CalendarWithDatePicker(props: any) {
    const localizer = momentLocalizer(moment);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentView, setCurrentView] = useState<"month" | "week" | "day">("week");
    const [isEdit, setIsEdit] = useState(false); // State to track if edit mode is on
    const [eventDetails, setEventDetails] = useState<any>(null); // State to store clicked event details

    const eventPropGetter = (event: any) => {
        const backgroundColor = event.status === "Open" ? "#E7F2DA" : "#e6e6fa";
        const borderColor = event.status === "Open" ? "#4caf50" : "#6a5acd";
        return {
            style: {
                backgroundColor,
                color: "#424242",
                borderRadius: "5px",
            },
        };
    };

    const events = props.events;

    const handleDateChange = (date: any) => {
        const startOfWeek = moment(date).startOf('week').toDate();
        setSelectedDate(startOfWeek);
    };

    const handleSelectSlot = (slotInfo: any) => {
        setSelectedDate(slotInfo.start);
        setCurrentView("day");
    };

    const handleNavigate = (action: string) => {
        if (action === 'TODAY') {
            const startOfWeek = moment().startOf('week').toDate();
            setSelectedDate(startOfWeek);
        } else if (action === 'PREV') {
            setSelectedDate((prev) => moment(prev).subtract(1, 'week').startOf('week').toDate());
        } else if (action === 'NEXT') {
            setSelectedDate((prev) => moment(prev).add(1, 'week').startOf('week').toDate());
        }
    };

    const sortedEvents = [...events].sort((a, b) => {
        const statusOrder: { [key: string]: number } = { Open: 1, Scheduled: 2, Closed: 3, Past: 4 };
        return (statusOrder[a.status] || 99) - (statusOrder[b.status] || 99);
    });


    const handleEventClick = (event: any) => {
        setEventDetails(event);
        setIsEdit(true);
        props.handleOpenMoadal()
    };

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={sortedEvents}
                startAccessor="start"
                endAccessor="end"
                view={currentView}
                date={selectedDate}
                onNavigate={(date) => setSelectedDate(date)}
                defaultView="week"
                eventPropGetter={eventPropGetter}
                selectable={true}
                onSelectEvent={handleEventClick}
                components={{
                    toolbar: (props) => (
                        <CustomToolbar
                            {...props}
                            label={selectedDate}
                            setCurrentView={setCurrentView}
                            onNavigate={handleNavigate}
                            onDateChange={handleDateChange}
                        />
                    ),
                    week: { header: (props: React.PropsWithChildren<HeaderProps>) => <WeekHeader {...props} /> },
                    event: (props) => <EventComponent {...props} />,
                }}
                style={{
                    height: '100%',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                }}
            />
        </div>
    );
}

export default CalendarWithDatePicker;
