    // Customizable Area Start
    import React, { useState, useEffect } from "react";

    import {

        Box,
        Button,
        InputLabel,
        InputAdornment,
        IconButton,
        Dialog,
        DialogContent,
        DialogActions,
        Grid,
        Select,
        MenuItem,
        TextField,
        Snackbar,
        ListItemText,
        Checkbox,
        styled,
    } from "@material-ui/core";
    import Alert from '@material-ui/lab/Alert';

    import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
    import DateFnsUtils from '@date-io/date-fns';
    import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
    import moment from "moment";

    interface Props {
        visible: boolean;
        onChange: (event: any) => void;
        onClose: () => void;
        onSubmitScheduleSurvey: (quickSchedule: boolean) => void;
        handleSelectedGroupList: (id: number) => void;
        data: any;
        groupList: any[];
        userList: any[];
        questions: any[];
        removeUsers: (id: number) => void;
        addUsers: (id: number) => void;
    }

    interface S {
        open: boolean;
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info' | undefined;
    }

    const ScheduleSurveyDialog = ({
        visible,
        onChange,
        onClose,
        onSubmitScheduleSurvey,
        handleSelectedGroupList,
        data,
        groupList,
        userList,
        questions,
        removeUsers,
        addUsers,
    }: Props) => {
        const [snackbar, setSnackbar] = useState<S>({ open: false, message: 'info', severity: 'success' });
        const [quickSchedule, setQuickSchedule] = useState<boolean>(false)
        const [isChecked, setIsChecked] = useState(false);
        const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
        const [flag , setFlag] = useState<boolean>(false);
        const [isIndividualUserAdded, setisIndividualUserAdded] = useState<boolean[]>([]);


        useEffect(() => {
            const individualUserAddedArray = userList.map(user => user.is_individual_user_added);
            setisIndividualUserAdded([...individualUserAddedArray]);
            return () => console.log("now returning")
        }, [data]);
        
        useEffect(() => {
            const individualUserAddedArray = userList.map(user => user.is_individual_user_added);
            setisIndividualUserAdded([...individualUserAddedArray]);
            const initialSelectedUsers = userList
                .filter(user => user.is_in_group || user.is_individual_user_added)
                .map(user => user.id);
            setSelectedUsers(initialSelectedUsers);
        }, [userList,flag]);

        const resetFormData = () => {
            onChange({ target: { name: 'selectedGroup', value: [] } });
            onChange({ target: { name: 'selectedStartDate', value: null } });
            onChange({ target: { name: 'selectedEndDate', value: null } });
            onChange({ target: { name: 'selectedStartTime', value: '' } });
            onChange({ target: { name: 'selectedEndTime', value: '' } });
        };


        const workingSlot = [
            "",
            "12:00 AM",
            "12:30 AM",
            "01:00 AM",
            "01:30 AM",
            "02:00 AM",
            "02:30 AM",
            "03:00 AM",
            "03:30 AM",
            "04:00 AM",
            "04:30 AM",
            "05:00 AM",
            "05:30 AM",
            "06:00 AM",
            "06:30 AM",
            "07:00 AM",
            "07:30 AM",
            "08:00 AM",
            "08:30 AM",
            "09:00 AM",
            "09:30 AM",
            "10:00 AM",
            "10:30 AM",
            "11:00 AM",
            "11:30 AM",
            "12:00 PM",
            "12:30 PM",
            "01:00 PM",
            "01:30 PM",
            "02:00 PM",
            "02:30 PM",
            "03:00 PM",
            "03:30 PM",
            "04:00 PM",
            "04:30 PM",
            "05:00 PM",
            "05:30 PM",
            "06:00 PM",
            "06:30 PM",
            "07:00 PM",
            "07:30 PM",
            "08:00 PM",
            "08:30 PM",
            "09:00 PM",
            "09:30 PM",
            "10:00 PM",
            "10:30 PM",
            "11:00 PM",
            "11:30 PM",
            "12:00 AM"
        ];
        

        const handleCloseSnackbar = () => {
            setSnackbar({ open: false, message: '', severity: 'success' });
        };

        const validateStartDate = (date: any) => {
            const start_date = new Date(date).setHours(0, 0, 0, 0);
            const current_date = new Date(new Date()).setHours(0, 0, 0, 0);

            // start date could'nt be greater than end date and could'nt be less than current date
            const selectedEndDate = data?.selectedEndDate;

            if (selectedEndDate && start_date) {
                const end_date = new Date(selectedEndDate).setHours(0, 0, 0, 0);
                if (end_date < start_date) {
                    setSnackbar({ open: true, message: 'Start date should be less than end date', severity: 'error' });
                    return false;
                }
            }

            // start date should be equal or greater than current date
            if (start_date < current_date) {
                setSnackbar({ open: true, message: 'Start date should be greater than current date', severity: 'error' });
                return false;
            }

            return true;
        };

        const validateEndDate = (date: any) => {
            // end date could'nt be less than start date
            const selectedStartDate = data?.selectedStartDate;
            const end_date = new Date(date).setHours(0, 0, 0, 0);

            if (selectedStartDate && end_date) {
                const start_date = new Date(selectedStartDate).setHours(0, 0, 0, 0);

                if (start_date > end_date) {
                    setSnackbar({ open: true, message: 'End date should be greater than start date', severity: 'error' });
                    return false;
                }
            }

            // end date should be equal or greater than current date
            const current_date = new Date(new Date()).setHours(0, 0, 0, 0);
            if (end_date < current_date) {
                setSnackbar({ open: true, message: 'End date should be greater than current date', severity: 'error' });
                return false;
            }

            return true;
        };

        const isEndDateAndStartDateSame = () => {
            const selectedEndDate = data?.selectedEndDate;
            const selectedStartDate = data?.selectedStartDate;
            if (selectedEndDate && selectedStartDate) {
                const start_date = new Date(selectedStartDate).setHours(0, 0, 0, 0);
                const end_date = new Date(selectedEndDate).setHours(0, 0, 0, 0);
                const isEndDateAndStartDateSame = start_date === end_date;

                return isEndDateAndStartDateSame;
            }

            return false;
        };

        const validateStartTime = (time: any) => {
            // start time could'nt be greater than end time
            const selectedEndTime = data?.selectedEndTime;
            if (selectedEndTime && time) {
                const [endHour, endMinute] = selectedEndTime.split(':').map((el: any) => el);
                if (isEndDateAndStartDateSame() && endHour && endMinute) {
                    const [startH, startMin] = time.split(':').map(Number);
                    if (startH > Number(endHour) || (startH === Number(endHour) && startMin >= Number(endMinute))) {
                        setSnackbar({ open: true, message: 'Start time should be less than end time', severity: 'error' });
                        return false;
                    }
                }
            }
            return true;
        };

        const validateEndTime = (time: any) => {
            // end time could'nt be less than start time
            const selectedStartTime = data?.selectedStartTime;
            if (selectedStartTime && time) {

                const [startHour, startMinute] = selectedStartTime.split(':').map((el: any) => el);
                if (isEndDateAndStartDateSame() && startHour && startMinute) {
                    const [endH, endMin] = time.split(':').map(Number);
                    if (endH < Number(startHour) || (endH === Number(startHour) && endMin <= Number(startMinute))) {
                        setSnackbar({ open: true, message: 'End time should be greater than start time', severity: 'error' });
                        return false;
                    }
                }
            }
            return true;
        };

        const quickHandler = () => {
            setQuickSchedule(prevState => !prevState)
        }

        const getCustomSlot = (time: string) => {
            if (!workingSlot.includes(time)) {
                return [...workingSlot, time]
            }
            return [...workingSlot]
        }

  

        return (
            <Dialog aria-labelledby="customized-dialog-title" open={visible} fullWidth maxWidth="sm"
                PaperProps={{
                    style: {
                        borderRadius: "16px",
                        boxShadow: "none",
                    },
                }}>
                <div id="survey-modal">
                <Box style={styles.surveyTitle}>
                    Schedule Survey
                </Box>
                <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel style={styles.label}>Select the group</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                style={styles.select}
                                name="selectedGroup"
                                data-test-id="group"
                                multiple
                                value={data?.selectedGroup}
                                onChange={(event) => onChange(event)}
                                displayEmpty
                                renderValue={(selected: any) => selected.length
                                    ? selected
                                        .map((id: any, index: number) => groupList.find((group) => group.id === id)?.name
                                        )
                                        .join(", ").replace(/^,\s/, "")
                                    : null}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null ,
                                        PaperProps: {
                                            style: {
                                                maxHeight:"50%",
                                                marginTop: "10px",
                                                borderRadius: "22px",
                                                border: "1px solid #E6E6E6",
                                            }
                                        }
                                    }}
                            >
                                <MenuItem value="" disabled>
                                    <em style={{ fontFamily: 'aeonik-regular'}}>Group Name</em>
                                </MenuItem>
                                {groupList.map((group: any,i:number) => (
                                    <StyledMenuItem
                                    key={group.id}
                                    value={group.id}
                                  >
                                        <ListItemText primary={group.name} style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            fontFamily: 'aeonik-regular'
                                        }} />
                                        <Checkbox style={{ color: "#3c5584" }}
                                            checked={data?.selectedGroup.indexOf(group.id) > -1} />
                                      </StyledMenuItem>
                                     
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel style={styles.label}>Select User</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                style={styles.select}
                                name="selectedUser"
                                data-test-id="user"
                                multiple
                                value={selectedUsers}
                                onChange={(event) => onChange(event)}
                                displayEmpty
                                renderValue={(selected: any) => {
                                    if (selected.length === 0) return null;
                                  
                                    return selected
                                      .map((id: any) => userList.find((user) => user.id === id)?.first_name)
                                      .filter((name: string | undefined) => name !== undefined) 
                                      .join(", ");
                                  }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: "40%", 
                                                marginTop: "10px",
                                                borderRadius: "22px",
                                                border: "1px solid #E6E6E6",
                                            }
                                        }
                                    }}
                            >
                                <MenuItem value="" disabled>
                                    <em style={{ fontFamily: 'aeonik-regular', }}>User List</em>
                                </MenuItem>
                                {userList.map((user: any, i: number) => (
                                     
                                     <StyledMenuItem
                                     key={user.id}
                                     value={user.id}
                                   >
                                        <ListItemText primary={user.first_name + " " + user.last_name} style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            fontFamily: 'aeonik-regular'
                                        }} />
                                        <Checkbox
                                            style={{ color: user.is_in_group ? "grey" : "#3c5584"}}
                                            disabled = {user.is_in_group}
                                            checked={isIndividualUserAdded[i] || user.is_in_group}
                                            onMouseDown={() => {
                                                if (user.is_individual_user_added || isChecked) {
                                                    setFlag(!flag);
                                                    removeUsers(user.id);
                                                    setSelectedUsers((prevSelected) =>
                                                        prevSelected.filter((userId) => userId !== user.id)
                                                    );
                                                } else {
                                                    setFlag(!flag);
                                                    addUsers(user.id);
                                                    setSelectedUsers((prevSelected) => [...prevSelected, user.id]);
                                                }

                                            }}

                                        />
                                  </StyledMenuItem>
                                     
                                ))}
                            </Select>
                        </Grid>
                       
                         {/* // start date */}
                         <Grid item xs={6}>
                            <InputLabel style={styles.label}>Start Date</InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    fullWidth
                                    name="selectedStartDate"
                                    disablePast={true}
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    placeholder="DD/MM/YYYY"
                                    data-test-id="startDate"
                                    value={data?.selectedStartDate || null}
                                    disabled={quickSchedule}
                                    onChange={(date: any) => {
                                        if (validateStartDate(date)) {
                                            const formattedDate = date.toISOString().substring(0, 10);
                                            onChange({
                                                target: { name: 'selectedStartDate', value: formattedDate }
                                            })
                                        }
                                    }}
                                    TextFieldComponent={(props: any) => (
                                        <TextField
                                            {...props}
                                            InputProps={{
                                                ...props.InputProps,
                                                style: { borderRadius: '10px', border: '1px solid #E0E0E0' },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {/* // start time */}
                        <Grid item xs={6}>
                            <InputLabel style={styles.label}>Time</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                style={styles.select}
                                name="selectedStartTime"
                                data-test-id="startTime"
                                disabled={quickSchedule}
                                value={
                                    data.selectedStartTime.includes("AM") || data.selectedStartTime.includes("PM")
                                        ? data.selectedStartTime
                                        : moment(data.selectedStartTime, "HH:mm").format("hh:mm A")
                                }
                                onChange={(event: any) => {
                                    if (validateStartTime(event?.target?.value)) {
                                        onChange(event);
                                    }
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    <em style={{ fontFamily: 'aeonik-regular', }}>09:00 AM</em>
                                </MenuItem>
                                {
                                    getCustomSlot(data?.selectedStartTime).map((ele: any, ind: any) => (
                                        <MenuItem key={ind} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <Button style={{
                                ...styles.quickScheduleBtnUnselected,
                                color: quickSchedule ? "white" : "#1C386D",
                                backgroundColor: quickSchedule ? "#1C386D" : "white"
                            }}
                                onClick={quickHandler}>Schedule in Next 1 Minute</Button>
                        </Grid>
                        {/* // end date */}
                        <Grid item xs={6}>
                            <InputLabel style={styles.label}>End Date</InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    fullWidth
                                    name="selectedEndDate"
                                    disablePast={true}
                                    minDate={data?.selectedStartDate || new Date()}
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    placeholder="DD/MM/YYYY"
                                    data-test-id="endDate"
                                    value={data?.selectedEndDate || null}
                                    onChange={(date: any) => {
                                        if (validateEndDate(date)) {
                                            const formattedDate = date.toISOString().substring(0, 10);
                                            onChange({
                                                target: { name: 'selectedEndDate', value: formattedDate }
                                            });
                                        }
                                    }}
                                    TextFieldComponent={(props: any) => (
                                        <TextField
                                            {...props}
                                            InputProps={{
                                                ...props.InputProps,
                                                style: { borderRadius: '10px', border: '1px solid #E0E0E0' },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {/* // end time */}
                        <Grid item xs={6}>
                            <InputLabel style={styles.label}>Time</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                style={styles.select}
                                name="selectedEndTime"
                                data-test-id="endTime"
                                value={
                                    data.selectedEndTime.includes("AM") || data.selectedEndTime.includes("PM")
                                        ? data.selectedEndTime
                                        : moment(data.selectedEndTime, "HH:mm").format("hh:mm A")
                                }
                                onChange={(event: any) => {
                                    if (validateEndTime(event?.target?.value)) {
                                        onChange(event)
                                    }
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    <em style={{ fontFamily: 'aeonik-regular'}}>10:00 AM</em>
                                </MenuItem>
                                {
                                    getCustomSlot(data?.selectedEndTime).map((ele: any, ind: any) => (
                                        <MenuItem key={ind} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12}>
                        <div style={styles.btnschedle}>
                            <Button
                                data-test-id="modal-btn"
                                style={styles.btnModal}
                                onClick={() => onSubmitScheduleSurvey(quickSchedule)}
                            >
                                Schedule
                            </Button>
                            <Button data-test-id="cancel-btn" style={styles.close} onClick={() => {
                            setQuickSchedule(false);
                            resetFormData();
                             onClose();
                            }}>Cancel</Button>
                        </div>
                    </Grid>
                </DialogActions>
                <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    open={snackbar.open}
                    onClose={handleCloseSnackbar}
                >
                    <Alert severity={snackbar.severity}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                </div>
            </Dialog>
        );
    }
 

    const StyledMenuItem = styled(MenuItem)({
        '&:not(:last-child)': {
          borderBottom: '1px solid #ccc',
        },
      });

    const styles = {
        label: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: 'aeonik-regular',
            fontWeight: 600,
            marginBottom: "10px"
        },
        select: {
            border: "1px solid #E0E0E0",
            borderRadius: "10px",
        },
        btnModal: {
            padding: '18px 32px',
            width: "max-content",
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
            fontSize: '16px',
            fontWeight: 700,
            color: 'rgba(255, 255, 255, 1)',
            lineHeight: 'normal',
            fontFamily: 'aeonik-regular',
            textTransform: "capitalize" as const,
        },
        close: {
            textTransform: "capitalize" as const,
            marginBottom: "20px",
            color: '#333333',
            fontSize: '18px',
            fontWeight: 600,
            fontFamily: 'aeonik-bold',
        },
        btnschedle: {
            display: 'flex',
            flexDirection: 'column' as const,
            alignItems: "center",
            gap: "10px"
        },
        surveyTitle: {
            color: "#202020",
            fontSize: "24px",
            fontWeight: 700,
            fontFamily: 'aeonik-regular',
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #E7E7E7",
            padding: "20px",
            marginBottom: '20px'
        },
        quickScheduleBtnUnselected: {
            padding: '18px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: 'normal',
            fontFamily: 'aeonik-regular',
            textTransform: "capitalize" as const,
            width: "100%",
            border: "1px solid #1C386D"
        }
    };

    export default ScheduleSurveyDialog;
    // Customizable Area End